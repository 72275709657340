<template>
  <section class="hero py-5">
    <b-container class="position-relative d-flex justify-content-center">
      <h1 class="text-center w-100 position-absolute">
        Making the most <br />
        out of software
      </h1>
      <div class="img">
        <b-img
          class="mw-100"
          :src="require('../../../assets/img/secure.gif')"
        />
      </div>
    </b-container>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.hero {
  h1 {
    max-width: 700px;
    font-size: 45px;
    font-weight: 700;
    line-height: 65px;
    margin: auto;
    text-transform: uppercase;
  }
  .img {
    top: 0;
    z-index: -1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
  }
}

@media (max-width: 750px) {
  .hero h1 {
    font-size: 35px;
    line-height: 50px;
  }
}
@media (max-width: 450px) {
  .hero h1 {
    font-size: 25px;
    line-height: 35px;
  }
}
</style>