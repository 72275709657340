<template>
  <main>
    <navigation />
    <section class="story py-5 bg-dark text-white position-relative">
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <b-container>
        <div class="mb-4">
          <b-row
            class="tab-navs mx-0"
            no-gutters
            align-h="center"
            align-v="center"
          >
            <b-col>
              <h2 class="font-weight-bold">Our Story</h2>
            </b-col>
          </b-row>
        </div>
        <article>
          Oh God, where do I start from? <br />
          <br />
          <b>Disclaimer:</b> Forgive me where I’ll use ‘I’ instead of ‘We’.
          <br />
          <br />
          <b>2018</b> <br />
          Soooooo it all started back in 2018, 1 of our first founder had an
          idea to break the long channel between farmers and consumers of their
          products by creating a direct link between collection, storage,
          distribution, and supply of fresh food using technology so he called
          me as someone he knew was a brilliant guy since high school (not
          flexing at all😎). <br />
          <br />
          Oooh No I forgot to introduce myself. Who am I? That’s a secret😀
          <br />
          <br />
          Fast forward to when he called me, he told me his idea and we started
          working on it but we didn’t have any developer at all that’s when we
          met Mr. Angel Investor, a brilliant software engineer (I’ll maybe tell
          you why we call him Angel investor later), joined the team and that's
          when we called it QUARKS GROUP. <br />
          <br />
          <b>2019</b> <br />
          It has been a hell of a journey since 2018, we dropped our first
          project in January 2019 after realising that it demanded a lot of
          funds and we didn’t even have enough to commute to work most of the
          time. We started thinking about other projects and in March 2019 we
          met our 4th founder and we started working with REMERA sector in
          developing the community based security fee collection system. This
          kinda boosted the clients’ trust in us and that’s when we started
          working on several projects. <br />
          <br />
          <b>2020</b> <br />
          This year was good despite the pandemic. We managed to sign big
          contracts especially in providing a payment platform to local
          businesses. <br />
          <br />
          <b>2021</b> <br />
          In this year, we launched our payment platform called PayPack and the
          feedback from our clients has been positive so far. <br />
          <br />
          If you’re reading this it’s probably that you’re interested in the
          services we offer and if it’s the case we’ll be honored to add you to
          our clients.
          <br />
          If you’re reading this just for the sake of stalking, feel at home. We
          love stalkers🤗 <br />
          <br />
          If you're reading this because you're a software engineer tryna find a
          job, slide in the DMs or whatever means of communication available on
          our website. No need to have a degree, you just have to be brilliant
          and driven.
          <br />
          <br />
          I’m sorry for the promise we gave you to tell you who the Angel
          investor is, by the time I was going to reveal his identity I spilled
          coffee on my laptop🥴, maybe that’s mother nature sending a sign that
          we shouldn’t reveal Mr Angel Investor’s identity.
          <br />
          <br />
          And of course as I expected, now they are telling me I managed to sell
          short all the brilliant ways we managed to come up with in the art of
          failure and disappointment. But that would be a horror movie, we'll
          keep it PG13 for the young people in the audience.
          <br /><br />
          See you when we see you
        </article>
      </b-container>
    </section>
    <footer-section />
  </main>
</template>

<script>
import navigation from "../sections/nav.vue";
import footerSection from "../sections/footer.vue";
export default {
  components: { navigation, footerSection },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
article {
  font-size: 17px;

  .container {
    max-width: 850px;
  }
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #83838338;
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
</style>
