<template>
  <section class="about bg-dark py-5" id="about">
    <b-container class="text-light">
      <h2 class="mb-4 font-weight-bold">ABOUT US</h2>
      <article>
        <p>
          <b>QUARKS GROUP Ltd</b> is a Rwandan software company that creates,
          develops, and scales cutting-edge mobile, web, and desktop technology
          solutions that propel its clients into the digital future.
        </p>
        <p>
          Since its founding in 2019, our company has worked to advance business
          and software product strategies. We are a committed team of high-IQ
          professionals with a wide range of relevant experience in software
          products and IT services. Since its inception, the company has
          developed and provided several technological solutions to various
          high-profile clients throughout the country.
        </p>
      </article>
      <b-row class="w-100 mb-4 mt-3">
        <b-col class="py-2" cols="12" md="6">
          <div class="bg-light text-dark p-3 h-100 rounded-0 mt-4">
            <h5 class="font-weight-bold">Mission</h5>
            <article>
              Our mission is to provide clients with the highest quality
              software development services possible by creating products that
              add value to their businesses.
            </article>
          </div>
        </b-col>
        <b-col class="py-2">
          <div class="bg-light text-dark p-3 h-100 rounded-0 mt-4">
            <h5 class="font-weight-bold">Vision</h5>
            <article>
              Our vision is to be the best software development company in the
              region, as well as the best payment platform provider.
            </article>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.about {
  article {
    line-height: 24px;
  }
}
</style>
