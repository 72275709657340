<template>
  <main>
    <navigation />
    <section class="products bg-dark py-5">
      <div class="mb-4 sticky-top bg-dark nav-container">
        <b-row class="tab-navs mx-0" align-h="center" align-v="center">
          <b-col class="py-2" cols="auto">
            <b-button
              :variant="selectedTab == 'owned' ? 'light' : 'outline-light'"
              @click="selectedTab = 'owned'"
            >
              Owned Products
            </b-button>
          </b-col>
          <b-col class="py-2" cols="auto">
            <b-button
              :variant="selectedTab == 'not-owned' ? 'light' : 'outline-light'"
              @click="selectedTab = 'not-owned'"
            >
              Commissioned Products
            </b-button>
          </b-col>
        </b-row>
      </div>
      <transition name="component-fade" mode="in-out">
        <b-container v-if="selectedTab == 'owned'">
          <!-- <b-row cols="12" v-for="(product, i) in owned_products" :key="i">
            <b-col class="text-light my-3">
              <b-row class="product">
                <b-col class="my-3" cols="12" md="6" xl="5">
                  <div class="preview overflow-hidden">
                    <a :href="product.link" target="_blank">
                      <b-img :src="product.url" />
                    </a>
                  </div>
                </b-col>
                <b-col class="my-3">
                  <h4 class="mb-3 font-weight-bold">
                    <a class="text-light" :href="product.link" target="_blank">
                      {{ product.name }}
                    </a>
                  </h4>
                  <article>
                    <p>
                      Paypack is a self-service payment platform. Our end goal
                      is to build and automate a payment gateway that is easy to
                      use, secure, and affordable.
                    </p>
                    <p>
                      Its design philosophy follows a developer-first approach
                      since they are the ones who are going to be intergrating
                      our payment gateway into their systems.
                    </p>
                    <p>
                      Clients with businesses that accept or issue payments can
                      use our payment gateway to receive and send funds.
                    </p>
                  </article>
                </b-col>
              </b-row>
            </b-col>
          </b-row> -->

          <b-row cols="12" v-for="(product, i) in owned_products" :key="i">
            <b-col class="text-light my-3">
              <b-row class="product">
                <!-- Image first on small screens, alternate layout on larger screens -->
                <b-col
                  class="my-3"
                  cols="12"
                  md="6"
                  xl="5"
                  :class="{
                    'order-md-1': i % 2 === 0,
                    'order-md-2': i % 2 !== 0,
                  }"
                >
                  <div class="preview overflow-hidden">
                    <a :href="product.link" target="_blank">
                      <b-img :src="product.url" />
                    </a>
                  </div>
                </b-col>
                <b-col
                  class="my-3"
                  cols="12"
                  md="6"
                  xl="7"
                  :class="{
                    'order-md-2': i % 2 === 0,
                    'order-md-1': i % 2 !== 0,
                  }"
                >
                  <h4 class="mb-3 font-weight-bold">
                    <a class="text-light" :href="product.link" target="_blank">
                      {{ product.name }}
                    </a>
                  </h4>
                  <article>
                    <p>
                      {{ product?.p1 }}
                    </p>
                    <p>{{ product?.p2 }}</p>
                    <p>
                      {{ product?.p3 }}
                    </p>
                  </article>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
        <b-container v-if="selectedTab == 'not-owned'">
          <b-row cols="12" cols-md="2">
            <b-col
              v-for="(product, i) in commisioned_products"
              :key="'not-owned' + i"
              class="text-light my-4"
            >
              <div class="product">
                <div class="preview overflow-hidden">
                  <a :href="product.link" target="_blank">
                    <b-img :src="product.url" />
                  </a>
                </div>
                <h5 class="mt-3">
                  <a class="text-light" target="_blank" :href="product.link">
                    {{ product.name }}
                  </a>
                </h5>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </transition>
    </section>
    <footer-section />
  </main>
</template>

<script>
import navigation from "../sections/nav.vue";
import footerSection from "../sections/footer.vue";
export default {
  components: { navigation, footerSection },
  data() {
    return {
      selectedTab: "owned",
      owned_products: [
        {
          name: "Paypack Payments",
          url: require("../../assets/img/products/paypack payments.png"),
          link: "https://payments.paypack.rw",
          p1: "Paypack is a self-service payment platform. Our end goal is to build and automate a payment gateway that is easy to use, secure, and affordable.",
          p2: "Its design philosophy follows a developer-first approach since they are the ones who are going to be integrating our payment gateway into their systems.",
          p3: "Clients with businesses that accept or issue payments can use our payment gateway to receive and send funds.",
        },
        {
          name: "Andasy",
          url: require("../../assets/img/products/andasy.png"),
          link: "https://andasy.io/",
          p1: "Andasy is a Cloud Service Provider that offers PaaS functionality, allowing developers to easily deploy and run their applications in the cloud without worrying about the underlying infrastructure.",
          // p2: "",
          // p3: "",
        },
        {
          name: "Paypck Events",
          url: require("../../assets/img/products/events.png"),
          link: "https://events.paypack.rw/",
          p1: "Paypack events is a platform that helps to discover and host events effortlessly",
          // p2: "",
          // p3: "",
        },
        {
          name: "Paypack Bills",
          url: require("../../assets/img/products/bills.png"),
          link: "https://play.google.com/store/apps/details?id=rw.paypack.bills",
          p1: "Paypack Bills is a mobile bill payment service in Rwanda. It enables users to pay bills quickly and easily via a mobile application.",
          // p2: "",
          // p3: "",
        },
        {
          name: "Rentals",
          url: require("../../assets/img/products/rentals.png"),
          link: "https://play.google.com/store/apps/details?id=com.rentals.rw&hl=en",
          p1: "Unlock the best rental opportunities with our app designed to connect renters with knowledgeable commissioners. Easily find experts who know the local market and have access to available houses. Browse listings, communicate directly with commissioners, and streamline your search for the perfect home. Discover a smarter way to rent today!",
        },
      ],
      commisioned_products: [
        {
          name: "Smartclass",
          url: require("../../assets/img/products/smartclass.png"),
          link: "https://www.smartclass.rw/",
        },
        // {
        //   name: "Nile Water Africa",
        //   url: require("../../assets/img/products/nile water africa.png"),
        //   link: "https://nilewaterafrica.com/",
        // },
        {
          name: "Nguyen CPA",
          url: require("../../assets/img/products/nguyen cpa.png"),
          link: "https://nguyencpa.net/",
        },
        // {
        //   name: "Nano Investment",
        //   url: require("../../assets/img/products/nano investment.png"),
        //   link: "https://nanoinvestment.rw/",
        // },
        {
          name: "NK3D Studio",
          url: require("../../assets/img/products/nk3d studio.png"),
          link: "https://www.nk3dstudio.rw/",
        },
        {
          name: "Royal Pets Center",
          url: require("../../assets/img/products/royal pets.png"),
          link: "https://www.royalpetscenter.rw/",
        },
        {
          name: "Rweru Environmental concern",
          url: require("../../assets/img/products/rweru environmental concern.png"),
          link: "https://www.environmentalconcern.co.rw/",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.products {
  .tab-navs {
    position: sticky;
    top: 0;
  }
  .preview {
    width: 100%;
    height: auto;
    min-height: 140px;
    user-select: none;
    position: relative;
    border: 0.1px solid #4a4a4a;
    background-color: #4a4a4a;

    img {
      width: 100%;
      aspect-ratio: 16/10;
      object-fit: cover;
      object-position: top;
    }

    &:hover {
      .link {
        transform: scale(1);
      }
    }

    .link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #00000099;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: scale(0);
      transition: all 0.2s linear;

      a {
        padding: 4px 10px;
        color: var(--light);
        transition: all 0.15s linear;
        text-decoration: none;
        &:hover {
          background-color: var(--light);
          color: var(--dark);
        }
      }
    }
  }
}
</style>
