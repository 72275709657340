<template>
  <section class="contact py-5 text-dark" id="contact">
    <b-container fluid class="px-2 px-md-4">
      <h2 class="text-center mb-5 font-weight-bold">GET IN TOUCH</h2>
      <b-row class="mx-0 mb-4" align-v="stretch" align-h="center">
        <b-col class="py-2" cols="12" sm="10" md="5" xl="4">
          <b-form class="bg-dark text-light px-4 py-4 rounded-0">
            <h4 class="mb-4 text-center text-white">Talk to us</h4>
            <b-form-group label="Names">
              <b-form-input />
            </b-form-group>
            <b-form-group label="Email Address">
              <b-form-input />
            </b-form-group>
            <b-form-group label="Phone Number">
              <b-form-input />
            </b-form-group>
            <b-form-group label="Message">
              <b-textarea rows="4" />
            </b-form-group>
            <b-form-group
              class="mb-0 d-flex align-items-center justify-content-end"
            >
              <b-button block variant="light" class="px-4">SEND</b-button>
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="12" sm="10" md="7" xl="8" class="py-2">
          <div class="map h-100">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d996.8707917245547!2d30.107695129164444!3d-1.9603786595905912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dca7f089aec63d%3A0xb86718790ecac6f9!2sQuarks%20Group!5e0!3m2!1sen!2srw!4v1633003459333!5m2!1sen!2srw"
              style="border: 0; min-height: 300px"
              allowfullscreen=""
              loading="lazy"
              class="w-100 h-100 rounded-0"
            ></iframe>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
