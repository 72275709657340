var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('navigation'),_c('section',{staticClass:"products bg-dark py-5"},[_c('div',{staticClass:"mb-4 sticky-top bg-dark nav-container"},[_c('b-row',{staticClass:"tab-navs mx-0",attrs:{"align-h":"center","align-v":"center"}},[_c('b-col',{staticClass:"py-2",attrs:{"cols":"auto"}},[_c('b-button',{attrs:{"variant":_vm.selectedTab == 'owned' ? 'light' : 'outline-light'},on:{"click":function($event){_vm.selectedTab = 'owned'}}},[_vm._v(" Owned Products ")])],1),_c('b-col',{staticClass:"py-2",attrs:{"cols":"auto"}},[_c('b-button',{attrs:{"variant":_vm.selectedTab == 'not-owned' ? 'light' : 'outline-light'},on:{"click":function($event){_vm.selectedTab = 'not-owned'}}},[_vm._v(" Commissioned Products ")])],1)],1)],1),_c('transition',{attrs:{"name":"component-fade","mode":"in-out"}},[(_vm.selectedTab == 'owned')?_c('b-container',_vm._l((_vm.owned_products),function(product,i){return _c('b-row',{key:i,attrs:{"cols":"12"}},[_c('b-col',{staticClass:"text-light my-3"},[_c('b-row',{staticClass:"product"},[_c('b-col',{staticClass:"my-3",class:{
                  'order-md-1': i % 2 === 0,
                  'order-md-2': i % 2 !== 0,
                },attrs:{"cols":"12","md":"6","xl":"5"}},[_c('div',{staticClass:"preview overflow-hidden"},[_c('a',{attrs:{"href":product.link,"target":"_blank"}},[_c('b-img',{attrs:{"src":product.url}})],1)])]),_c('b-col',{staticClass:"my-3",class:{
                  'order-md-2': i % 2 === 0,
                  'order-md-1': i % 2 !== 0,
                },attrs:{"cols":"12","md":"6","xl":"7"}},[_c('h4',{staticClass:"mb-3 font-weight-bold"},[_c('a',{staticClass:"text-light",attrs:{"href":product.link,"target":"_blank"}},[_vm._v(" "+_vm._s(product.name)+" ")])]),_c('article',[_c('p',[_vm._v(" "+_vm._s(product?.p1)+" ")]),_c('p',[_vm._v(_vm._s(product?.p2))]),_c('p',[_vm._v(" "+_vm._s(product?.p3)+" ")])])])],1)],1)],1)}),1):_vm._e(),(_vm.selectedTab == 'not-owned')?_c('b-container',[_c('b-row',{attrs:{"cols":"12","cols-md":"2"}},_vm._l((_vm.commisioned_products),function(product,i){return _c('b-col',{key:'not-owned' + i,staticClass:"text-light my-4"},[_c('div',{staticClass:"product"},[_c('div',{staticClass:"preview overflow-hidden"},[_c('a',{attrs:{"href":product.link,"target":"_blank"}},[_c('b-img',{attrs:{"src":product.url}})],1)]),_c('h5',{staticClass:"mt-3"},[_c('a',{staticClass:"text-light",attrs:{"target":"_blank","href":product.link}},[_vm._v(" "+_vm._s(product.name)+" ")])])])])}),1)],1):_vm._e()],1)],1),_c('footer-section')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }